import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BtnPrimary from "../components/buttons/primary"
import BtnSecondary from "../components/buttons/secondary"

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <div className="masthead relative">
        <div className="masthead hidden lg:block">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_desktop
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="masthead block lg:hidden">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_mobile
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
          <div className="container py-5 md:mt-36">
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
              {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
              <span className="inline md:block">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                  "" && <span className="inline">&nbsp;</span>}
              </span>
              {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                <span className="inline md:block md:mt-1">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                </span>
              )}
            </h1>
          </div>
        </div>
      </div>
      <section className="section sectionFirst">
        <div className="container py-10">
          <p>
            Eurokars Auto is now an official BMW dealer, and we’re celebrating
            this milestone with attractive perks. Find your dream BMW here, and
            let us elevate your ownership experience.
          </p>
          <span class="border-t border-neutral-400 block w-full my-8"></span>
          <h3 className="font-bold">
            For a limited time only, purchase any BMW model to enjoy:
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div>
              <ul className="list-disc list-outside pl-5">
                <li>BMW Service Inclusive Plus</li>
                <li>Complimentary BMW Advanced Car Eye 3.0 Pro</li>
                <li>Interior & Exterior Grooming Package</li>
              </ul>
            </div>
            <div>
              <ul className="list-disc list-outside pl-5">
                <li>Dent Removal and Curb Rash Repair</li>
                <li>Free upgrade to Rikecool Platinum Grade Window Film</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-3 my-8">
            <Link
              to="/test-drive/"
              data-gtm-category="Offers_Limited Time"
              data-gtm-action="Clicked_Btn"
              data-gtm-label="Book a test drive"
            >
              <BtnPrimary cta="Book a test drive" />
            </Link>
            <Link
              to="/all-models/"
              data-gtm-category="Offers_Limited Time"
              data-gtm-action="Clicked_Btn"
              data-gtm-label="View all models"
            >
              <BtnSecondary cta="View all models" />
            </Link>
          </div>
          <p className="mt-6 text-sm">Terms and conditions apply.</p>
          <h2 className="text-2xl lg:text-3xl xl:text-4xl uppercase font-light mt-12 lg:pt-8">
            More Perks, More Joy.
          </h2>
          <span class="border-t border-neutral-400 block w-full my-3"></span>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 md:gap-x-5 gap-y-8 md:gap-y-0">
            <div className="">
              <StaticImage
                src="../images/img-offers-bmw-service-inclusive-plus.jpg"
                alt="BMW Service"
              />
              <h3 className="font-bold mt-3">Enjoy greater peace of mind</h3>
              <p className="pr-4">
                Our BMW Service Inclusive Plus package covers all maintenance
                work as well as additional wear-and-tear repairs including any
                required Original BMW Parts.
              </p>
            </div>
            <div className="">
              <StaticImage
                src="../images/img-offers-bmw-advanced-car-eye-3.jpg"
                alt="BMW Advanced Car Eye 3.0 Pro"
              />
              <h3 className="font-bold mt-3">Always looking out for you</h3>
              <p className="pr-4">
                The BMW Advanced Car Eye 3.0 Pro monitors what is happening in
                front of and behind the vehicle even when it is parked.
              </p>
            </div>
            <div className="">
              <StaticImage
                src="../images/img-offers-bmw-pristine.jpg"
                alt="BMW Pristine"
              />
              <h3 className="font-bold mt-3">Keep your BMW pristine</h3>
              <p className="pr-0">
                Our complimentary interior & exterior grooming package and dent
                removal and curb rash repair entitlements can be redeemed as and
                when needed.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row space-x-0 md:space-x-3 my-8">
            <Link
              to="/test-drive/"
              data-gtm-category="Offers_More Perks"
              data-gtm-action="Clicked_Btn"
              data-gtm-label="Book a test drive"
            >
              <BtnPrimary cta="Book a test drive" />
            </Link>
            <Link
              to="/all-models/"
              data-gtm-category="Offers_More Perks"
              data-gtm-action="Clicked_Btn"
              data-gtm-label="View all models"
            >
              <BtnSecondary cta="View all models" />
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "offers" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default AboutPage

const seoDetails = {
  title: "Eurokars Auto | Opening Deals",
  description:
    "Eurokars Auto is now an official BMW dealer, and we’re celebrating this milestone with attractive perks.",
  keywords: "Eurokars, Eurokars Auto, BMW Savings, Latest BMW Deals, BMW Deals",
  image: "og-eka-offers.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-8FJZCNFV3R"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=GTM-M7DKTD9"
    ></script>
  </>
)
